<template>
  <HFunnelTopBar :no-offer="false" />
  <slot />
</template>

<script lang="ts" setup>
useHead({
  bodyAttrs: {
    class: 'funnel'
  }
})
</script>
